import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Nav from "../Shared/Nav";
import About from "../About/About";
// import Contact from "../Contact/Contact";
import MultiContact from "../Contact/MultiContact";
import Homepage from "../Homepage/Homepage";
import Login from "../Login/Login";
import Search from "../Search/Search";
// import TestLandingPage from "../Search/TestLandingPage";
import Notification from "../Notification/Notification";
// for activity tracking
import ReactGA from 'react-ga4'
import API from '../Shared/API'; // Import the centralized API utility
import Embed from "../Embed/Embed";
import TranscriptView from "../Search/TranscriptView";

function initializeAnalytics() {
  const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  if (GA_TRACKING_ID) {
    ReactGA.initialize(GA_TRACKING_ID);
    return true;
  }
  console.warn("Google Analytics Tracking ID not found.");
  return false;
}


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      token: '',
      username: '',
      userId: 0,
      timezone: '',
      failureMessage: '',
      authChecked: false,  // track if we've finished verifying the token
    }
  }

  async componentDidMount() {
    try {
      const userToken = localStorage.getItem('token');
      if (userToken) {
        // Verify the token
        const verifyResponse = await API.post('/api/token-verify/', { token: userToken });

        // Check if token is valid
        if (verifyResponse.data.user) {
          // Fetch user profile
          const profileResponse = await API.get('/user_auth/profiles/');
          const currentUser = profileResponse.data.user ? profileResponse.data.user.username : null;
          const currentUserId = profileResponse.data.user ? profileResponse.data.user.id : null;
          const currentUserTimezone = profileResponse.data ? profileResponse.data.timezone : null;

          // Initialize Google Analytics
          initializeAnalytics();
          ReactGA.set({ userId: currentUserId });

          // Update state
          this.setState({
            loggedIn: true,
            token: userToken,
            username: currentUser,
            userId: currentUserId,
            timezone: currentUserTimezone,
            failureMessage: '',
            authChecked: true,  // finished checking, user is logged in
          });
        } else {
          // Token is invalid
          this.setState({ loggedIn: false, authChecked: true });
        }
      } else {
        // No token stored
        this.setState({ loggedIn: false, authChecked: true });
      }
    } catch (error) {
      console.error("Error during token verification or fetching profile:", error);
      this.setState({ loggedIn: false, authChecked: true });
    }
  }

  // follow user_id prop and update GA userId as needed
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user_id !== this.props.user_id) {
      ReactGA.set({ userId: this.props.user_id });
    }
  }

  render() {
    const { loggedIn, username, userId, timezone, authChecked } = this.state;

    // 1) If we haven't finished checking auth, show a spinner
    if (!authChecked) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-theme-blue"></div>
        </div>
      );
    }

    // If user is not logged in, only let them access public routes
    // but let's define /embed/:id as a public route

    return (
      <Router>
      <Switch>
        {/* Public routes */}
        <Route
          exact
          path="/"
          render={(routeProps) => (
            <Homepage
              {...routeProps}       // if you want the usual router props (history, match, etc.)
              loggedIn={loggedIn}
              username={username}
              userId={userId}
              timezone={timezone}
            />
          )}
        />
        <Route
          path="/transcript/:videoId"
          render={(routeProps) => (
            <TranscriptView
              {...routeProps}
              loggedIn={loggedIn}
              username={username}
              userId={userId}
              timezone={timezone}
            />
          )}
        />
        <Route
          exact
          path="/about"
          render={(routeProps) => (
            <About
              {...routeProps}
              loggedIn={loggedIn}
              username={username}
              userId={userId}
              timezone={timezone}
            />
          )}
        />
        <Route
          exact
          path="/contact"
          render={(routeProps) => (
            <MultiContact
              {...routeProps}
              loggedIn={loggedIn}
              username={username}
              userId={userId}
              timezone={timezone}
            />
          )}
        />
        <Route
          exact
          path="/login"
          render={(routeProps) => (
            <Login
              {...routeProps}
              loggedIn={loggedIn}
              username={username}
              userId={userId}
              timezone={timezone}
              path={window.location.pathname}
            />
          )}
        />

        {/* Public embed route */}
        <Route
          path="/embed/:embedId"
          render={(routeProps) => (
            <Embed
              {...routeProps}
              // Because embed is public, no need for loggedIn checks
            />
          )}
        />

        {/* Protected routes: if user not logged in, show <Login> or redirect */}
        <Route
          exact
          path="/search"
          render={(routeProps) =>
            loggedIn ? (
              <Search 
                {...routeProps}            
                loggedIn={loggedIn}
                username={username}
                userId={userId}
                timezone={timezone} />
            ) : (
              <Login {...routeProps} />
            )
          }
        />
        <Route
          exact
          path="/settings"
          render={(routeProps) =>
            loggedIn ? (
              <Notification {...routeProps} 
              loggedIn={loggedIn}
              username={username}
              userId={userId}
              timezone={timezone} />
            ) : (
              <Login {...routeProps} />
            )
          }
        />

        {/* Fallback if no route matched */}
        <Route render={(routeProps) => (
            <Homepage
              {...routeProps}  
              loggedIn={loggedIn}
              username={username}
              userId={userId}
              timezone={timezone}
            />
          )} />
      </Switch>
      </Router>

    );
  }
}

export default App;
