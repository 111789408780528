import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Nav from '../Shared/Nav';
import Transcript from "./Transcript";
import API from '../Shared/API';
import ReactGA from 'react-ga4';

const TranscriptView = ({ loggedIn, username, userId, timezone }) => {
  const { videoId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [resultdata, setResultdata] = useState(null);
  const [meetingSummary, setMeetingSummary] = useState(null);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchTranscript = async () => {
      if (!videoId) {
        setError("No video URL provided");
        setIsLoading(false);
        return;
      }

      try {
        // Track page view
        ReactGA.send({
          hitType: "pageview",
          page: `/transcript/${videoId}`,
          title: "Transcript Direct View"
        });

        // Decode the URL parameter - it should be the full video URL
        let decodedVideoUrl = videoId;
        try {
          decodedVideoUrl = decodeURIComponent(videoId);
          console.log('Decoded video URL from parameter:', decodedVideoUrl);
        } catch (decodingError) {
          console.warn('Unable to decode URL parameter, using as-is:', videoId);
        }

        console.log('Fetching transcript with video URL:', decodedVideoUrl);
        
        // Make EXACTLY the same search request format as when clicking a search result
        const searchRequest = {
          searchTerm: '',
          filters: [{ 
            field: 'video_url', 
            values: [decodedVideoUrl], // Use the full decoded video URL
            type: 'all'
          }],
          sortDirection: 'asc',
          sortField: 'segment',
          resultsPerPage: 500
        };
        
        console.log('Search request:', JSON.stringify(searchRequest, null, 2));
        
        const searchResponse = await API.post('/user_auth/search/', searchRequest);
        console.log('Search results count:', searchResponse?.data?.results?.length || 0);
        
        if (searchResponse?.data?.results?.length > 0) {
          // Use the first result as our transcript data
          const result = searchResponse.data.results[0];
          
          // Transform to expected format
          const transcriptData = {
            id: { raw: result.id.raw },
            video_url: { raw: result.video_url.raw },
            video_title: { raw: result.video_title.raw },
            video_length: { raw: result.video_length.raw },
            upload_date: { raw: result.upload_date.raw },
            locality_name: result.locality_name,
            state: { raw: result.state.raw },
            transcript: { raw: result.transcript.raw },
            segment_start: { raw: result.segment_start?.raw || "00:00:00" },
            timestamp_url: { raw: result.timestamp_url?.raw || result.video_url.raw }
          };
          
          setResultdata(transcriptData);
          
          // Also try to fetch the meeting summary
          try {
            const summaryResponse = await API.get(`/user_auth/summary/${result.id.raw}`);
            if (summaryResponse.status === 200 || summaryResponse.status === 201) {
              console.log('Meeting Summary:', summaryResponse.data);
              setMeetingSummary(summaryResponse.data.meeting);
            }
          } catch (summaryError) {
            console.error('Error fetching summary:', summaryError);
          }
          
          setIsLoading(false);
        } else {
          throw new Error(`No transcript found with video URL: ${decodedVideoUrl}`);
        }
      } catch (error) {
        console.error('Error fetching transcript:', error);
        setError(`Failed to load transcript: ${error.message}`);
        setIsLoading(false);
      }
    };

    fetchTranscript();
  }, [videoId]);

  return (
    <div>
      <header className="sticky top-0 border-b-2 border-theme-gray">
        <Nav loggedIn={loggedIn} username={username} timezone={timezone} />
      </header>
      
      <div className="flex flex-col w-full bg-white md:flex-row">
        {/* Left blue bar - desktop only */}
        <div className="h-screen overflow-y-auto px-3 bg-theme-blue hidden md:block"></div>
        
        {/* Main content */}
        <div className="flex-1 px-4 sm:px-10 overflow-y-auto">
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <div className="flex flex-col items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-theme-blue"></div>
                <span className="mt-4 text-gray-600 text-lg">Loading transcript...</span>
              </div>
            </div>
          ) : error ? (
            <div className="mx-9 mt-20 flex flex-col items-center justify-center">
              <svg viewBox="0 0 24 24" width="64" height="64" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="text-red-500">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="12"></line>
                <line x1="12" y1="16" x2="12.01" y2="16"></line>
              </svg>
              <h2 className="mt-5 text-gray-800 text-xl font-bold">Error</h2>
              <div className="mt-3 text-gray-600">{error}</div>
              
              <div className="mt-6">
                <a href="/" className="bg-theme-blue text-white px-4 py-2 rounded">Return to Home</a>
              </div>
            </div>
          ) : resultdata ? (
            <Transcript
              resultdata={resultdata}
              searchTerm=""
              isLoading={false}
              targetSegment={null}
              meetingSummary={meetingSummary}
              timezone={timezone}
              directView={true} // Important: This indicates it's a direct view
            />
          ) : (
            <div className="mx-9 mt-20 flex flex-col items-center justify-center">
              <h2 className="mt-5 text-gray-800 text-xl font-bold">No transcript found</h2>
              <p className="mt-3 text-gray-600">The requested transcript could not be located.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TranscriptView; 